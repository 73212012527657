import React from "react";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";

const GRAPHQL_API_URL = `${process.env.GATSBY_WORDPRESS_URL}/graphql`;

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({ uri: GRAPHQL_API_URL }),
});


export default client;